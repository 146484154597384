import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBoxService } from '../../services/dialog-box.service';

@Component({
  selector: 'app-update-confirmation-box',
  templateUrl: './update-confirmation-box.component.html',
  styleUrl: './update-confirmation-box.component.scss',
})
export class UpdateConfirmationBoxComponent {
  constructor(
    public dialogBoxService: DialogBoxService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogBoxService.closeUpdateConfirmationBox();
  }
}
