import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private API_URL = `${environment.apiUrl}`;
  private basicAuth =
    'Basic ' + btoa(`${environment.authUsername}:${environment.authPassword}`);
  responseData: any;

  constructor(private http: HttpClient) {}

  login(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/auth/login/`, data, { headers })
    );
  }

  getGLogin(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/utilities/glogin/`));
  }

  register(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/auth/registration/`, data, {
        headers,
      })
    );
  }

  forgot(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/auth/password/reset/`, data, {
        headers,
      })
    );
  }

  confirmReset(data: any) {
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/auth/password/reset/confirm/`, data)
    );
  }
  changeEmail(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.put(`${environment.apiUrl}/profile/change-email/`, data, {
        headers,
      })
    );
  }

  changePassword(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/profile/change-password/`, data, {
        headers,
      })
    );
  }

  logout() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/auth/logout/`, { headers })
    );
  }
  getAuth(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/auth/user/`));
  }

  updateAuth(data: any): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.put(`${environment.apiUrl}/auth/user/`, data, {
        headers,
      })
    );
  }

  getProfile(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/profile/`));
  }

  setProfile(data: any): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.put(`${environment.apiUrl}/profile/`, data, {
        headers,
      })
    );
  }

  setFrontEndSettings(data: any): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return firstValueFrom(
      this.http.put(
        `${environment.apiUrl}/profile/frontend-settings/account/`,
        data,
        {
          headers,
        }
      )
    );
  }

  setProfileImage(formData: any): Promise<any> {
    return firstValueFrom(
      this.http.put(`${this.API_URL}/profile/profile-picture/`, formData)
    );
  }
  getSchedules(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/schedules/`));
  }
  getLessons(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/lessons/`));
  }

  resendConfirmationEmail(data: any): Promise<any> {
    return firstValueFrom(
      this.http.post(`${this.API_URL}/auth/resend-confirmation/`, data)
    );
  }

  getTermsAndConditions(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/terms/retrieve/`));
  }

  getTacStatus(): Promise<any> {
    return firstValueFrom(this.http.get(`${this.API_URL}/terms/update/`));
  }

  acceptTac(userId: string, tacId: string, accept: boolean): Promise<any> {
    const data = {
      user: userId,
      term_and_condition: tacId,
      accepted: accept,
    };

    return firstValueFrom(
      this.http.post(`${this.API_URL}/terms/create-accept/`, data)
    );
  }

  deactivate(data: any): Promise<any> {
    return firstValueFrom(
      this.http.delete(`${this.API_URL}/profile/delete/`, { body: { ...data } })
    );
  }

  getFrontEndAccountSettings(): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/profile/frontend-settings/account/`)
    );
  }

  getFrontEndEmailSettings(): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/profile/frontend-settings/email/`)
    );
  }

  getFrontEndNamesSettings(): Promise<any> {
    return firstValueFrom(
      this.http.get(`${this.API_URL}/profile/frontend-settings/lesson-names/`)
    );
  }

  setFrontEndAccountSettings(
    tutorial: boolean,
    weekend: boolean,
    holiday: boolean,
    color_change: boolean
  ): Promise<any> {
    const data = {
      show_tutorial: tutorial,
      allow_weekend_work: weekend,
      allow_holiday_work: holiday,
      allow_color_change: color_change,
    };
    return firstValueFrom(
      this.http.patch(
        `${this.API_URL}/profile/frontend-settings/account/`,
        data
      )
    );
  }

  setFrontEndNameSettings(
    lesson: string,
    unit: string,
    topic: string,
    activity: string
  ): Promise<any> {
    const data = {
      lesson: lesson,
      unit: unit,
      topic: topic,
      activity: activity,
    };

    return firstValueFrom(
      this.http.patch(
        `${this.API_URL}/profile/frontend-settings/lesson-names/`,
        data
      )
    );
  }
}
