import {
  Component,
  Inject,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogBoxService } from '../../../../services/dialog-box.service';
import { ToasterService } from '../../../../services/toaster.services';
import { ScheduleService } from '../../schedules/services/schedules.service';
import { PreviousRouteService } from '../../../../services/previous-route.service';
import { ScheduleDetailComponent } from '../schedule-detail/schedule-detail.component';
import { BlockScheduleCalendarComponent } from '../block-schedule-calendar/block-schedule-calendar.component';

interface schedTemplate {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-block-schedule-form',
  templateUrl: './block-schedule-form.component.html',
  styleUrl: './block-schedule-form.component.scss',
})
export class BlockScheduleFormComponent implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<BlockScheduleFormComponent>,
    public dialogBoxService: DialogBoxService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private scheduleService: ScheduleService,
    private toaster: ToasterService,
    private previousRouteService: PreviousRouteService,
    private scheduleDetailComponent: ScheduleDetailComponent,
    private blockScheduleCalendarComponent: BlockScheduleCalendarComponent
  ) {}
  @ViewChild('scheduleNameInput') scheduleNameInput!: ElementRef;
  selectedValue: string = '0';
  templates: schedTemplate[] = [
    { value: '0', viewValue: 'Custom' },
    { value: '1', viewValue: 'Traditional' },
    { value: '2', viewValue: 'A/B Block' },
    { value: '3', viewValue: 'Flex' },
  ];

  blockScheduleForm: FormGroup = this._formBuilder.group({
    name: new FormControl('', [Validators.required]),
    days_per_cycle: new FormControl(5, [Validators.required]),
    adjust_around_holidays: new FormControl(false),
    adjust_around_pro_d: new FormControl(false),
    weekends_off: new FormControl(true),
    calendar_week_cycle: new FormControl(true),
  });
  lessonId: string = '';

  ngAfterViewInit() {
    this.scheduleNameInput.nativeElement.focus();
  }

  ngOnInit(): void {
    this.lessonId = this.previousRouteService.prevLessonValue ?? '';
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
  back(): void {
    this.dialogRef.close(false);
  }
  submitForm() {
    if (this.blockScheduleForm.valid) {
      this.blockScheduleForm.value.days_per_cycle = this.blockScheduleForm.value
        .calendar_week_cycle
        ? 5
        : this.blockScheduleForm.value.days_per_cycle;
      this.scheduleService
        .createBlockSchedule(this.data.schedid, this.blockScheduleForm.value)
        .then((response: any) => {
          this.toaster.show(
            'success',
            'Success',
            'Schedule successfully created.',
            5000
          );
          const urlSegments = this.router.url.split('/');
          const hasCreateBlockSched =
            urlSegments.includes('create-block-sched') ||
            urlSegments.includes('edit?new=true');
          if (hasCreateBlockSched) {
            this.dialogRef.close(false);
            let url = '';
            let redirect: string = `/lesson-planner/schedule/${this.data.schedid}/`;
            if (url) {
              this.dialogRef.close(false);
              redirect = url;
            }
            this.lessonId ? history.back() : this.router.navigate([redirect]);
          } else {
            this.reloadPage();
          }
        })
        .catch((error) => {
          this.toaster.show(
            'error',
            'Error',
            'Encountered an error on creating schedule. Please contact the administration.',
            5000
          );
        });

      return;
    }
    return;
  }
  handleChangeTemplate() {
    switch (this.blockScheduleForm.value.template_schedule) {
      case '1':
        this.blockScheduleForm.patchValue({
          periods_per_day: '5',
          default_period_length: '60',
          days_per_cycle: '5',
          adjust_around_holidays: false,
          adjust_around_pro_d: false,
          weekends_off: true,
        });
        break;
      case '2':
        this.blockScheduleForm.patchValue({
          periods_per_day: '7',
          default_period_length: '60',
          days_per_cycle: '7',
          adjust_around_holidays: false,
          adjust_around_pro_d: false,
          weekends_off: true,
        });
        break;
      case '3':
        this.blockScheduleForm.patchValue({
          periods_per_day: '3',
          default_period_length: '30',
          days_per_cycle: '9',
          adjust_around_holidays: false,
          adjust_around_pro_d: false,
          weekends_off: true,
        });
        break;
      default:
        this.blockScheduleForm.patchValue({
          periods_per_day: '',
          default_period_length: '',
          days_per_cycle: '',
          adjust_around_holidays: false,
          adjust_around_pro_d: false,
          weekends_off: true,
        });
        break;
    }
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  reloadPage() {
    window.location.reload();
  }
}
