import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBoxService } from '../../services/dialog-box.service';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent implements OnInit {

  constructor(public dialogBoxService: DialogBoxService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {}

  closeDialog() {
    this.dialogBoxService.closeConfirmationBox();
  }
}
