import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationBoxComponent } from '../components/confirmation-box/confirmation-box.component';
import { UpdateConfirmationBoxComponent } from '../components/update-confirmation-box/update-confirmation-box.component';
import { CloseConfirmationBoxComponent } from '../components/close-confirmation-box/close-confirmation-box.component';
import { BlockScheduleFormComponent } from '../pages/lesson-planner/components/block-schedule-form/block-schedule-form.component';
import { BlockScheduleCalendarComponent } from '../pages/lesson-planner/components/block-schedule-calendar/block-schedule-calendar.component';

@Injectable({
  providedIn: 'root',
})
export class DialogBoxService {
  private confirmationDialog: MatDialogRef<any, any> | undefined = undefined; // Initialize the property
  private updateconfirmationDialog: MatDialogRef<any, any> | undefined =
    undefined; // Initialize the property
  private closeconfirmationDialog: MatDialogRef<any, any> | undefined =
    undefined; // Initialize the property
  private blockScheduleDialog: MatDialogRef<any, any> | undefined = undefined; // Initialize the property
  private blockScheduleCalendarDialog: MatDialogRef<any, any> | undefined =
    undefined; // Initialize the property

  constructor(private dialog: MatDialog) {}

  openConfirmationDialog(data: any): void {
    this.confirmationDialog = this.dialog.open(ConfirmationBoxComponent, {
      width: '320px',
      height: 'auto',
      minWidth: 'auto',
      panelClass: 'confirmationBox',
      disableClose: true,
      data: data,
    });
    this.confirmationDialog.afterClosed().subscribe((result: any) => {});
  }
  closeConfirmationBox(): void {
    if (this.confirmationDialog) {
      this.confirmationDialog.close();
    }
  }
  openUpdateConfirmationDialog(data: any): void {
    this.updateconfirmationDialog = this.dialog.open(
      UpdateConfirmationBoxComponent,
      {
        width: '320px',
        height: 'auto',
        minWidth: 'auto',
        panelClass: 'updateConfirmationBox',
        disableClose: true,
        data: {
          title: data.title,
          action: data.action,
        },
      }
    );
    this.updateconfirmationDialog.afterClosed().subscribe((result: any) => {});
  }
  closeUpdateConfirmationBox(): void {
    if (this.updateconfirmationDialog) {
      this.updateconfirmationDialog.close();
    }
  }
  openCloseConfirmationDialog(data: any): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.closeconfirmationDialog = this.dialog.open(
      CloseConfirmationBoxComponent,
      {
        width: '320px',
        height: 'auto',
        minWidth: 'auto',
        panelClass: 'closeConfirmationBox',
        disableClose: true,
        data: data,
      }
    );
    this.closeconfirmationDialog.afterClosed().subscribe((result: any) => {
      subject.next(result);
      subject.complete();
    });

    return subject.asObservable();
  }
  openCloseConfirmationBox(): void {
    if (this.closeconfirmationDialog) {
      this.closeconfirmationDialog.close();
    }
  }
  openBlockScheduledialog(data: any): void {
    this.blockScheduleDialog = this.dialog.open(BlockScheduleFormComponent, {
      width: '700px',
      height: 'auto',
      minWidth: 'auto',
      panelClass: 'blockScheduleBox',
      disableClose: false,
      data: {
        name: data.name,
        start_date: data.start_date,
        schedid: data.schedid,
        action: data.action,
      },
    });
    this.blockScheduleDialog.afterClosed().subscribe((result: any) => {});
  }
  closeBlockScheduledialog(): void {
    if (this.blockScheduleDialog) {
      this.blockScheduleDialog.close();
    }
  }
  openBlockScheduleCalendardialog(data: any): void {
    this.blockScheduleCalendarDialog = this.dialog.open(
      BlockScheduleCalendarComponent,
      {
        width: '90%',
        height: 'auto',
        minWidth: 'auto',
        panelClass: 'blockScheduleCalendarBox',
        disableClose: true,
        data: {
          color: data.color,
          class_name: data.class_name,
          class_info: data.class_info,
          sched_id: data.sched_id,
          blockschedid: data.blockschedid,
          action: data.action,
        },
      }
    );
    this.blockScheduleCalendarDialog
      .afterClosed()
      .subscribe((result: any) => {});
  }
  closeBlockScheduleCalendardialog(): void {
    if (this.blockScheduleCalendarDialog) {
      this.blockScheduleCalendarDialog.close();
    }
  }
}
