import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PreviousRouteService {
  prevScheduleValue: string | undefined;
  prevLessonValue: string | undefined;
  prevUnitValue: string | undefined;
  prevTopicValue: string | undefined;
  prevActivityValue: string | undefined;

  constructor() {}
}
