import { Component, OnInit, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule,
} from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ScheduleService } from '../../schedules/services/schedules.service';
import { ToasterService } from '../../../../services/toaster.services';
import { DialogBoxService } from '../../../../services/dialog-box.service';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrl: './schedule-detail.component.scss',
})
@Injectable({
  providedIn: 'root',
})
export class ScheduleDetailComponent implements OnInit {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private _location: Location,
    private scheduleService: ScheduleService,
    private toaster: ToasterService,
    private dialogBoxService: DialogBoxService,
    private ngxTour: TourService
  ) {}
  card: any;
  schedules: any = [];
  scheduleData: any;
  subjectData: any;
  noteData: any;
  scheduleInfo: any;
  schedId: string = this.route.snapshot.params['schedid'];
  id = this.route.snapshot.paramMap.get('id');
  steps_schedule: IStepOption[] = [];
  secondCalendarDate = new Date();
  reloadCalendar: boolean = false;

  reloadComponent(schedid: string) {
    console.log('reloadComponent');
    this.scheduleService.getScheduleDetail(schedid).then((response: any) => {
      this.schedules = response;
    });
    this.scheduleService.getNoteData().then((response: any) => {
      this.noteData = response.results;
    });
  }

  ngOnInit(): void {
    if (this.schedId) {
      this.steps_schedule = [
        {
          stepId: 'adjust.schedule',
          anchorId: 'adjust.schedule',
          content:
            'Great! You’ve created an initial schedule for this term. Now you need to modify it to match your actual schedule. You can start with adjusting the end times for a period. You can also merge periods for those classes that can take two or more periods long. When you’re done, go to the next page to see your term details and start assigning classes to your schedule.',
          title: 'Adjusting your Schedule',
          enableBackdrop: true,
          showProgress: false,
          placement: {
            xPosition: 'before',
            yPosition: 'above',
          },
          showArrow: false,
          endBtnTitle: 'Ok',
          nextBtnTitle: 'Ok',
          prevBtnTitle: '',
          isOptional: true,
          closeOnOutsideClick: true,
          popoverClass: 'remove-prev-btn center',
        },
      ];
      this.scheduleService
        .getScheduleDetail(this.schedId)
        .then((response: any) => {
          this.schedules = response;
        });
      this.scheduleService.getNoteData().then((response: any) => {
        this.noteData = response.results;
      });
    }
  }
  ngOnChanges(): void {
    if (this.schedId) {
      this.scheduleService
        .getScheduleDetail(this.schedId)
        .then((response: any) => {
          this.schedules = response;
        });
    }
  }
  goBack() {
    this.router.navigate(['/lesson-planner/plans']);
  }

  editSchedule() {
    this.goTo('/lesson-planner/schedule/' + this.schedId + '/edit');
  }

  goTo(url: string) {
    this.router.navigate([url]);
  }
  goPlanNow() {
    this.router.navigate([`./plan-now`], { relativeTo: this.route });
  }
  deleteSchedule(id: string) {
    const action = () => {
      this.scheduleService
        .deleteScheduleInformation(id)
        .then((response: any) => {
          this.toaster.show(
            'success',
            'Success',
            'Term deleted successfully',
            5000
          );
          this.goTo(`/lesson-planner/plans`);
        })
        .catch((error) => {
          this.toaster.show('error', 'Error', error.error.detail, 5000);
        });
      this.dialogBoxService.closeConfirmationBox();
    };
    this.dialogBoxService.openConfirmationDialog({
      title: 'Delete this term',
      body: `Are you sure you want to delete this term? <br/>
      The plans of the term cannot be restored after deletion.
      <span class="d-block my-1"></span><b>Note:</b> The lesson content will not
      be deleted with this action and can be reused in plans in other terms.`,
      action: action,
    });
  }
  savePlan() {
    const formData = {
      lesson: {
        id: this.id,
      },
      term: {
        id: this.schedId,
      },
    };
    this.scheduleService
      .createPlannerbyTermIdLessonId(formData)
      .then((response: any) => {
        this.toaster.show(
          'success',
          'Success',
          'Lesson Planner is created successfully.',
          5000
        );
        this.router.navigate([`/lesson-planner/plans/`]);
      });
  }

  onMonthChanged(date: Date) {
    this.secondCalendarDate = date;
  }
}
