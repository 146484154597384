import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogBoxService } from '../../services/dialog-box.service';

@Component({
  selector: 'app-close-confirmation-box',
  templateUrl: './close-confirmation-box.component.html',
  styleUrl: './close-confirmation-box.component.scss',
})
export class CloseConfirmationBoxComponent {
  constructor(
    public dialogRef: MatDialogRef<CloseConfirmationBoxComponent>,
    public dialogBoxService: DialogBoxService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close(false);
  }
  back(): void {
    this.dialogRef.close(true);
  }
}
