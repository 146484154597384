<div class="px-3 pb-4 confirm-dialog-box">
  <h2 class="text-center mt-3">
    @if(data.icon) {
    <mat-icon>{{ data.icon }}</mat-icon>
    } @else {
    <img src="./assets/images/delete.png" alt="book-icon" />
    }
  </h2>
  <div class="mat-typography text-center">
    <h2>{{ data.title }}</h2>
    <p [innerHtml]="data.body"></p>
  </div>
  <div class="mt-3 text-center">
    <button
      mat-flat-button
      cdkFocusInitial
      color="basic"
      (click)="closeDialog()"
      class="btn-cancel mx-1"
    >
      {{ data.secondaryBtn ?? "Cancel" }}
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      color="warn"
      (click)="data.action()"
      class="btn-delete mx-1"
    >
      {{ data.primaryBtn ?? "Delete" }}
    </button>
  </div>
</div>
