import {
  Component,
  Inject,
  ViewChild,
  TemplateRef,
  EventEmitter,
  Output,
  Input,
  OnInit,
  Injectable,
} from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ScheduleService } from '../../schedules/services/schedules.service';
import { LessonService } from '../../lessons/services/lessons.service';
import { ToasterService } from '../../../../services/toaster.services';
import { DialogBoxService } from '../../../../services/dialog-box.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { LocalStorageService } from '../../../../services/ls-service';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { filter } from 'rxjs/operators';
import { raw } from 'express';
import { C } from '@fullcalendar/core/internal-common';

interface Day {
  id: string;
  name: string;
  term: string;
  children: Period[];
}
interface Period {
  id: string;
  name: string;
  type: string;
  order: number;
  start_time: string;
  end_time: string;
  class_infos: any;
  merge: any;
  selected: boolean;
  class_period_id: any;
}
@Component({
  selector: 'app-block-schedule-calendar',
  templateUrl: './block-schedule-calendar.component.html',
  styleUrl: './block-schedule-calendar.component.scss',
})
@Injectable({
  providedIn: 'root',
})
export class BlockScheduleCalendarComponent implements OnInit {
  @ViewChild('DayDialogTemplate') DayDialogTemplate!: TemplateRef<any>;
  @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
  @Output() menuClosed: EventEmitter<void> = new EventEmitter<void>();
  @Input() blockid: string = '';
  @Input() schedules: any;
  @ViewChild(MatMenuTrigger) menuCellsOptions!: MatMenuTrigger;
  dialogRef: MatDialogRef<any> | undefined;
  constructor(
    private scheduleService: ScheduleService,
    private lessonService: LessonService,
    private toaster: ToasterService,
    private dialogBoxService: DialogBoxService,
    private route: ActivatedRoute,
    private router: Router,
    private ls: LocalStorageService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private location: Location,
    private dialogBlockSched: MatDialogRef<BlockScheduleCalendarComponent>
  ) {}
  blockiddata = this.data;
  schedid = this.blockiddata?.sched_id
    ? this.blockiddata?.sched_id
    : this.route.snapshot.params['schedid'];
  scheduleInfo: any;
  days: Day[] = [];
  displayedColumns: string[] = ['period'];
  inputDayPeriod: string = '';
  inputDayPeriodStartTime: string = '';
  inputDayPeriodEndTime: string = '';
  inputDayId: string = '';
  selectedCells = new Set<string>();
  selection: any[] = [];
  multiselectedPeriods: any[] = [];
  classes: any[] = [];
  lessons: any[] = [];
  selectedClass: any = null;
  selectedLesson: any = null;
  selectedPeriod: any = null;
  inputClassName: string = '';
  createdClass: string = '';
  logfrom: number = 0;
  logto: number = 0;
  start: number = 0;
  end: number = 0;
  frontEndNames = this.ls.getFrontEndNames();
  timeTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#ffff',
      buttonColor: '#F4AE3F',
    },
    dial: {
      dialBackgroundColor: '#F4AE3F',
    },
    clockFace: {
      clockHandColor: '#F4AE3F',
    },
  };
  getDaysId: string[] = [];

  calendarOptions: CalendarOptions = {
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    height: 600,
    initialView: 'resourceTimeGridDay',
    plugins: [
      dayGridPlugin,
      interactionPlugin,
      timeGridPlugin,
      resourceTimeGridPlugin,
    ],
    allDayText: 'All Day',
    nowIndicator: true,
    headerToolbar: false,
    scrollTime: '07:00:00', //this.getCurrentTime(),
    droppable: true,
    editable: true,
    selectable: true,
    snapDuration: '00:15:00',
    slotDuration: '00:30:00', // 1 minute time slots
    eventDrop: (info) => this.handleEventDrop(info),
    eventResize: (info) => this.handleResizeEvent(info),
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    },
  };

  assignClassForm: FormGroup = this.formBuilder.group({
    startTime: new FormControl('', [Validators.required]),
    endTime: new FormControl('', [Validators.required]),
  });

  handleResizeEvent(info: any) {
    const data = {
      block_day: info.event.extendedProps.dayId,
      class_info: info.event.extendedProps.class_info_id,
      start_time: this.getTime(info.event.start),
      end_time: this.getTime(info.event.end),
    };
    this.scheduleService
      .updateSchedulerClass(this.schedid, this.blockid, info.event.id, data)
      .then((response: any) => {
        this.toaster.show(
          'success',
          'Success',
          'Period successfully updated.',
          5000
        );
      })
      .catch((error) => {
        this.toaster.show('error', 'Error', 'Unable to update a period.', 5000);
      });
  }

  handleEventDrop(info: any): void {
    let resource = info.event.getResources();
    const resourceId = resource.map(function (resource: any) {
      return resource.id;
    });
    const data = {
      block_day: this.getDaysId[resourceId[0]],
      class_info: info.event.extendedProps.class_info_id,
      start_time: this.getTime(info.event.start),
      end_time: this.getTime(info.event.end),
    };
    this.scheduleService
      .updateSchedulerClass(this.schedid, this.blockid, info.event.id, data)
      .then((response: any) => {
        if (response) {
          this.toaster.show(
            'success',
            'Success',
            'Period successfully updated.',
            5000
          );
        }
      })
      .catch((error) => {
        let errorTxt = 'Unable to update a period.';
        if (error.error[0]) {
          errorTxt = 'Unable to update a period. ' + error.error[0];
        }
        this.toaster.show('error', 'Error', errorTxt, 5000);
        info.revert();
      });
  }

  getTime(date: Date): string {
    const now = date;
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  onMenuClosed(): void {
    this.days.map((day: any) => {
      day.children.map((period: any) => {
        period.selected = false;
      });
    });
  }

  loadEvents(schedid: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.scheduleService
        .getScheduler(schedid)
        .then((response: any) => {
          this.scheduleInfo = response[0];
          if (this.scheduleInfo) {
            const days = this.scheduleInfo.days;
            const localDate = new Date();
            const year = localDate.getFullYear();
            const month = String(localDate.getMonth() + 1).padStart(2, '0');
            const day = String(localDate.getDate()).padStart(2, '0');
            const today = `${year}-${month}-${day}`;
            let events = days.reduce((acc: any[], day: any) => {
              this.getDaysId.push(day.id);
              if (day.children) {
                day.children.forEach((child: Period) => {
                  if (child.class_infos) {
                    child.class_infos.forEach((classInfo: any) => {
                      acc.push({
                        id: child.class_period_id
                          ? child.class_period_id[0]
                          : null,
                        resourceId: day.order,
                        dayId: day.id,
                        title: classInfo.name,
                        start: new Date(
                          `${today}T${child.start_time}`
                        ).toISOString(),
                        end: new Date(
                          `${today}T${child.end_time}`
                        ).toISOString(),
                        color: classInfo.lesson.color,
                        class_info_id: classInfo.id,
                      });
                    });
                  }
                });
              }
              return acc;
            }, []);

            let resources = days.map((day: Day, order: number) => {
              return {
                id: order,
                title: day.name,
                dayId: day.id,
              };
            });

            this.calendarOptions.events = events;
            this.calendarOptions.resources = resources;

            resolve();
          }
        })
        .catch((error) => reject(error));
    });
  }

  addCalendarEvent(event: any) {
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.addEvent(event);
  }

  updateCalendarEvent(eventId: string, updatedEventData: any) {
    console.log(updatedEventData);
    let calendarApi = this.calendarComponent.getApi();
    let event = calendarApi.getEventById(eventId);
    console.log(event);

    if (event) {
      event.setStart(updatedEventData.start);
      event.setEnd(updatedEventData.end);
    } else {
      console.error('Event not found');
    }
  }

  removeCalendarEvent(eventId: string) {
    let calendarApi = this.calendarComponent.getApi();
    let event = calendarApi.getEventById(eventId);

    if (event) {
      event.remove();
    } else {
      console.log('Event not found');
    }
  }

  removeAllCalendarEvents() {
    let calendarApi = this.calendarComponent.getApi();
    let events = calendarApi.getEvents();

    events.forEach((event) => event.remove());
  }

  ngOnInit(): void {
    this.calendarOptions.select = (info) => {
      const id = info.resource ? info.resource.extendedProps['dayId'] : null;
      const start = this.getTime(info.start);
      const end = this.getTime(info.end);
      this.assignClassForm.patchValue({
        startTime: this.convertTo12Hour(start),
        endTime: this.convertTo12Hour(end),
      });
      this.openDialog(this.DayDialogTemplate, {
        title: 'Assign Class',
        option: 1,
        type: 'class',
        data: {
          block_schedule: this.blockid,
          block_day: id,
          start_time: start,
          end_time: end,
          raw_start_time: info.start,
          raw_end_time: info.end,
          resourceId: info.resource?.id,
        },
      });
    };
    this.calendarOptions.eventClick = (info) => {
      const start = info.event.start ? this.getTime(info.event.start) : '';
      const end = info.event.end ? this.getTime(info.event.end) : '';
      this.assignClassForm.patchValue({
        startTime: this.convertTo12Hour(start),
        endTime: this.convertTo12Hour(end),
      });
      let resource = info.event.getResources();
      const resourceId = resource.map(function (resource: any) {
        return resource.id;
      });
      const id = info.event.extendedProps
        ? info.event.extendedProps['dayId']
        : null;
      const class_info_id = info.event.extendedProps
        ? info.event.extendedProps['class_info_id']
        : null;
      this.openDialog(this.DayDialogTemplate, {
        title: 'Assigned Class',
        option: 2,
        type: 'class',
        data: {
          block_schedule: this.blockid,
          block_day: info.event.id,
          start_time: start,
          end_time: end,
          raw_start_time: info.event.start,
          raw_end_time: info.event.end,
          resourceId: resourceId,
          class_info_id: class_info_id,
        },
      });
    };
    this.blockid = this.blockiddata?.blockschedid
      ? this.blockiddata?.blockschedid
      : this.blockid;
    if (this.blockid !== undefined || this.blockid !== '') {
      this.loadEvents(this.schedid);
      if (this.blockiddata?.blockschedid !== undefined) {
        this.classes[0] = {
          name: this.blockiddata?.class_name,
          id: this.blockiddata?.class_info,
          lesson: {
            color: this.blockiddata?.color,
          },
        };
        this.selectedClass = this.blockiddata?.class_info;
      }
      this.scheduleService
        .getScheduleDetail(this.schedid)
        .then((response: any) => {
          this.classes = response.classes;
        });
      this.lessonService.getLessons().then((response: any) => {
        this.lessons = response.results;
      });
    }
  }
  getPeriodTime(day: Day, periodOrder: number): string {
    const period = day.children.find((p) => p.order === periodOrder);
    return period ? `${period.start_time} - ${period.end_time}` : '';
  }

  handleClickCell(rowIndex: number, colIndex: number): void {
    this.inputDayPeriod = '';
    if (rowIndex === 0) {
      this.inputDayPeriod = this.days[colIndex - 1].name;
    } else {
      this.inputDayPeriod = this.days[0].children[rowIndex - 1].name;
      this.inputDayPeriodStartTime =
        this.days[0].children[rowIndex - 1].start_time;
      this.inputDayPeriodEndTime = this.days[0].children[rowIndex - 1].end_time;
      this.selectedPeriod = this.days[0].children[rowIndex - 1];
    }
    if (colIndex > 0) {
      this.inputDayId = this.days[colIndex - 1].id;
    }
  }

  handleSelectedCell(element: Day, column: string): boolean {
    return this.selectedCells.has(`${element.children}-${column}`);
  }

  openDialog(templateRef: TemplateRef<any>, data: any): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.data = data;
    this.dialogRef = this.dialog.open(templateRef, { data });

    this.dialogRef.afterClosed().subscribe((result) => {
      this.selectedClass = this.blockiddata?.class_info
        ? this.blockiddata?.class_info
        : this.createdClass;
    });
  }

  actionBlockDayPeriod(
    option: number,
    type: string,
    others: { [key: string]: any } = {}
  ): void {
    switch (type) {
      case 'day':
        if (option === 1) {
          const data = {
            block_schedule: this.blockid,
          };
          this.scheduleService
            .createBlockScheduleDay(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Day successfully added.',
                  5000
                );
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show('error', 'Error', 'Unable to add a day.', 5000);
            });
        } else if (option === 2) {
          if (!this.inputDayPeriod) {
            this.toaster.show(
              'error',
              'Error',
              'Please enter a day label.',
              5000
            );
            return;
          }
          const data = {
            name: this.inputDayPeriod,
          };
          this.scheduleService
            .updateBlockScheduleDay(this.blockid, this.inputDayId, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Day successfully updated.',
                  5000
                );
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a day.',
                5000
              );
            });
        } else if (option === 3) {
          this.scheduleService
            .deleteBlockScheduleDay(this.blockid, this.inputDayId)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Day successfully removed.',
                5000
              );
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to remove a day.',
                5000
              );
            });
        }
        break;
      case 'period':
        if (option === 1) {
          const data = {
            block_schedule: this.blockid,
          };
          this.scheduleService
            .createBlockSchedulePeriod(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Period successfully added.',
                  5000
                );
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to add a period.',
                5000
              );
            });
        } else if (option === 2) {
          if (!this.inputDayPeriod) {
            this.toaster.show(
              'error',
              'Error',
              'Please enter a day label.',
              5000
            );
            return;
          }
          const data = {
            periods: [this.selectedPeriod.id],
            name: this.inputDayPeriod,
            order: this.selectedPeriod.order,
            start_time: this.selectedPeriod.start_time,
            end_time: this.selectedPeriod.end_time,
          };
          this.scheduleService
            .updateBlockSchedulePeriod(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Period successfully updated.',
                  5000
                );
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a period.',
                5000
              );
            });
        } else if (option === 3) {
          this.scheduleService
            .deleteBlockSchedulePeriod(this.blockid)
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Period successfully removed.',
                5000
              );
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to remove a period.',
                5000
              );
            });
        } else if (option === 4) {
          const [startHour, startMinute] = this.inputDayPeriodStartTime
            .split(':')
            .map(Number);
          const [endHour, endMinute] = this.inputDayPeriodEndTime
            .split(':')
            .map(Number);

          const startDate = new Date();
          startDate.setHours(startHour, startMinute);

          const endDate = new Date();
          endDate.setHours(endHour, endMinute);
          if (!this.inputDayPeriodStartTime || !this.inputDayPeriodEndTime) {
            this.toaster.show(
              'error',
              'Error',
              'Please check start and end time. Fields are required.',
              5000
            );
            return;
          } else {
            if (startDate > endDate) {
              this.toaster.show(
                'error',
                'Error',
                'Please enter a valid start and end time.',
                5000
              );
              return;
            }
          }
          const data = {
            periods: [this.selectedPeriod.id],
            name: this.selectedPeriod.name,
            order: this.selectedPeriod.order,
            start_time: this.inputDayPeriodStartTime,
            end_time: this.inputDayPeriodEndTime,
          };
          this.scheduleService
            .updateBlockSchedulePeriod(this.blockid, data)
            .then((response: any) => {
              if (response) {
                this.toaster.show(
                  'success',
                  'Success',
                  'Period successfully updated.',
                  5000
                );
                if (this.dialogRef) {
                  this.dialogRef.close();
                }
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a period.',
                5000
              );
            });
        }
        break;
      case 'class':
        if (option === 1) {
          if (!this.selectedClass) {
            this.toaster.show(
              'error',
              'Error',
              'Select class info to proceed.',
              5000
            );
            return;
          }
          if (
            this.convertTimeFormat(this.assignClassForm.value.startTime) >=
            this.convertTimeFormat(this.assignClassForm.value.endTime)
          ) {
            this.toaster.show(
              'error',
              'Error',
              'Start time should not be equal or greater than the end time.',
              5000
            );
            return;
          }
          const data = {
            block_schedule: others['block_schedule'],
            block_day: others['block_day'],
            class_info: this.selectedClass,
            start_time: this.convertTimeFormat(
              this.assignClassForm.value.startTime
            ),
            end_time: this.convertTimeFormat(
              this.assignClassForm.value.endTime
            ),
          };

          let getClass = this.classes.find(
            (classItem) => classItem.id === this.selectedClass
          );
          let getLesson = this.lessons.find(
            (lessonItem) => lessonItem.id === this.selectedLesson
          );
          let lessonColor = '';
          if (getClass.lesson === undefined) {
            lessonColor = getLesson.color;
          }
          const localDate = new Date();
          const year = localDate.getFullYear();
          const month = String(localDate.getMonth() + 1).padStart(2, '0');
          const day = String(localDate.getDate()).padStart(2, '0');
          const today = `${year}-${month}-${day}`;
          this.scheduleService
            .assignSchedulerClass(this.schedid, others['block_schedule'], data)
            .then((response: any) => {
              if (response.class_info) {
                const event = {
                  id: response.id,
                  dayId: response.block_day,
                  title: response.class_info_name,
                  class_info_id: response.class_info,
                  resourceId: others['resourceId'],
                  start: new Date(
                    `${today}T${this.convertTimeFormat(
                      this.assignClassForm.value.startTime
                    )}`
                  ).toISOString(),
                  end: new Date(
                    `${today}T${this.convertTimeFormat(
                      this.assignClassForm.value.endTime
                    )}`
                  ).toISOString(),
                  color: getClass.lesson ? getClass.lesson.color : lessonColor,
                };
                this.addCalendarEvent(event);
                this.toaster.show(
                  'success',
                  'Success',
                  'Classes asssigned successfully.',
                  5000
                );
              } else {
                this.toaster.show(
                  'error',
                  'Error',
                  'Error assigning classes. Please contact administrator.',
                  5000
                );
              }
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'This class has already been assigned to this day.',
                5000
              );
            });
        } else if (option === 2) {
          this.scheduleService
            .removeSchedulerPeriod(
              this.schedid,
              this.blockid,
              others['block_day']
            )
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Class unassigned successfully.',
                5000
              );
              this.removeCalendarEvent(others['block_day']);
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to unassign a class.',
                5000
              );
            });
        } else if (option === 3) {
          if (
            this.convertTimeFormat(this.assignClassForm.value.startTime) >=
            this.convertTimeFormat(this.assignClassForm.value.endTime)
          ) {
            this.toaster.show(
              'error',
              'Error',
              'Start time should not be equal or greater than the end time.',
              5000
            );
            return;
          }
          const data = {
            block_day: this.getDaysId[others['resourceId']],
            class_info: others['class_info_id'],
            start_time: this.convertTimeFormat(
              this.assignClassForm.value.startTime
            ),
            end_time: this.convertTimeFormat(
              this.assignClassForm.value.endTime
            ),
          };
          const localDate = new Date();
          const year = localDate.getFullYear();
          const month = String(localDate.getMonth() + 1).padStart(2, '0');
          const day = String(localDate.getDate()).padStart(2, '0');
          const today = `${year}-${month}-${day}`;
          this.scheduleService
            .updateSchedulerClass(
              this.schedid,
              this.blockid,
              others['block_day'],
              data
            )
            .then((response: any) => {
              this.toaster.show(
                'success',
                'Success',
                'Period successfully updated.',
                5000
              );
              this.updateCalendarEvent(others['block_day'], {
                start: new Date(
                  `${today}T${this.convertTimeFormat(
                    this.assignClassForm.value.startTime
                  )}`
                ).toISOString(),
                end: new Date(
                  `${today}T${this.convertTimeFormat(
                    this.assignClassForm.value.endTime
                  )}`
                ).toISOString(),
              });
              if (this.dialogRef) {
                this.dialogRef.close();
              }
            })
            .catch((error) => {
              this.toaster.show(
                'error',
                'Error',
                'Unable to update a period.',
                5000
              );
            });
        } else if (option === 6) {
          if (!this.selectedLesson) {
            this.toaster.show(
              'error',
              'Error',
              'Lesson info is required.',
              5000
            );
            return;
          }
          if (!this.inputClassName) {
            this.toaster.show(
              'error',
              'Error',
              'Class name is required.',
              5000
            );
            return;
          }
          this.lessonService
            .getLessonDetailsById(this.selectedLesson)
            .then((response: any) => {
              const subjectId = response.subject[0].id;
              const classFormData = {
                name: this.inputClassName,
                lesson: this.selectedLesson,
                subject: subjectId,
              };
              this.lessonService
                .createClassesOnSchedule(this.schedid, classFormData)
                .then((response: any) => {
                  this.createdClass = response.id;
                  const formData = {
                    lesson: {
                      id: this.selectedLesson,
                    },
                    term: {
                      id: this.schedid,
                    },
                    class_info: this.createdClass,
                  };
                  this.lessonService
                    .createPlannerbyLessonIdTermId(formData)
                    .then((response: any) => {
                      this.toaster.show(
                        'success',
                        'Success',
                        'Class is created successfully.',
                        5000
                      );
                      if (this.dialogRef) {
                        this.dialogRef.close();
                      }
                      if (others['option'] === 1) {
                        this.classes.push({
                          name: this.inputClassName,
                          id: response.class_info,
                        });
                        this.openDialog(this.DayDialogTemplate, {
                          title: 'Assign Class',
                          option: 1,
                          type: 'class',
                          data: others['data'],
                        });
                      } else {
                        this.openDialog(this.DayDialogTemplate, {
                          title: 'Merge Periods',
                          option: 5,
                          type: 'class',
                          data: others['data'],
                        });
                      }
                      this.inputClassName = '';
                    });
                });
            });
        }
        break;
      default:
        return;
        break;
    }
  }
  handleEnterKey(event: KeyboardEvent, option: number, type: string): void {
    if (event.key === 'Enter') {
      this.actionBlockDayPeriod(option, type);
      return;
    }
  }
  onSelectionStart(rowIndex: number, colIndex: number): void {
    this.days[colIndex].children[rowIndex].selected =
      !this.days[colIndex].children[rowIndex].selected;
    this.logfrom = rowIndex;
  }
  onSelectionEnd(rowIndex: number, colIndex: number) {
    this.onMenuClosed();
    this.logto = rowIndex;
    this.multiselectedPeriods = [];
    if (this.logfrom != this.logto) {
      this.start = this.logfrom < this.logto ? this.logfrom : this.logto;
      this.end = this.logto > this.logfrom ? this.logto : this.logfrom;
      for (let i = this.start; i <= this.end; i++) {
        this.days[colIndex].children[i].selected = true;
        this.multiselectedPeriods.push(this.days[colIndex].children[i]);
      }
    }
    this.inputDayId = this.days[colIndex].id;
  }
  optionsBlockSchedule(option: number): void {
    switch (option) {
      case 1:
        this.scheduleService
          .clearBlockSchedule(this.blockid, true)
          .then((response: any) => {
            this.toaster.show(
              'success',
              'Success',
              'Start over successfull.',
              5000
            );
          })
          .catch((error) => {
            this.toaster.show(
              'error',
              'Error',
              'Error encountered. Please contact the administration',
              5000
            );
          });
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        break;
      case 2:
        this.scheduleService
          .clearBlockSchedule(this.blockid, false)
          .then((response: any) => {
            this.toaster.show(
              'success',
              'Success',
              'Classes are cleared successfully',
              5000
            );
            this.removeAllCalendarEvents();
          })
          .catch((error) => {
            this.toaster.show(
              'error',
              'Error',
              'Error encountered. Please contact the administration',
              5000
            );
          });
        if (this.dialogRef) {
          this.dialogRef.close();
        }
        break;
      default:
        break;
    }
  }

  createBlockScheduleDialog(id: string) {
    const action = () => {
      return;
    };
    this.dialog.closeAll();
    this.dialogBoxService.openBlockScheduledialog({
      schedid: id,
      action: action,
    });
  }

  deleteBlockSchedule(): void {
    const action = () => {
      this.scheduleService
        .deleteScheduler(this.schedid, this.blockid)
        .then((response: any) => {
          this.toaster.show(
            'success',
            'Success',
            'Schedule deleted successfully',
            5000
          );
          const currentUrl = this.router.url;
          if (this.dialogBlockSched.id) {
            this.dialogBlockSched.close();
            this.router
              .navigateByUrl(`/lesson-planner/schedule/${this.schedid}`, {
                skipLocationChange: false,
              })
              .then(() => {
                this.router.navigate([currentUrl]);
              });
          } else {
            this.reloadPage();
            this.router
              .navigateByUrl('/', {
                skipLocationChange: true,
              })
              .then(() => {
                this.router.navigate([currentUrl]);
              });
          }
        })
        .catch((error) => {
          this.toaster.show(
            'error',
            'Error',
            'Error encountered. Please contact the administration',
            5000
          );
        });
      this.dialogBoxService.closeConfirmationBox();
    };
    this.dialogBoxService.openConfirmationDialog({
      title: 'Delete schedule',
      body: `Are you sure you want to delete this schedule? <br/>
       The contents of the schedule can not be restored after
      deletion`,
      action: action,
    });
  }

  goBack() {
    history.back();
  }

  goTo(url: string) {
    if (this.blockiddata?.blockschedid !== undefined) {
      this.router.navigate([
        '/lesson-planner/schedule/' + this.blockiddata.sched_id,
      ]);
      setTimeout(() => {
        this.router.navigate([url]);
      }, 10);
      setTimeout(() => {
        this.blockiddata.action();
      }, 1000);
      return;
    }
    this.router.navigate([url]);
  }
  formatTimeTo12Hour(timeString: string) {
    // Parse the time string to a Date object
    const date = new Date(`1970-01-01T${timeString}Z`);

    // Format the time to 12-hour format with AM/PM
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date;
  }
  convertTo12Hour(time: string): string {
    let [hours, minutes] = time.split(':').map(Number);
    let period = 'AM';

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }
    const hourString = hours.toString().padStart(2, '0');
    const minuteString = minutes.toString().padStart(2, '0');

    return `${hourString}:${minuteString} ${period}`;
  }
  convertTimeFormat(time: string): string {
    // Parse the input time
    const [timePart, modifier] = time.split(' '); // Split into time and AM/PM
    let [hours, minutes] = timePart.split(':').map(Number); // Split hours and minutes

    // Convert hours based on AM/PM
    if (modifier === 'PM' && hours < 12) {
      hours += 12; // Convert PM hours to 24-hour format
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0; // Convert 12 AM to 00 hours
    }

    // Format the hours, minutes, and seconds
    const formattedTime = `${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}:00`;
    return formattedTime;
  }
  convertToSpecificDateTime(timeString: string): Date {
    const targetDate = new Date('2024-10-31'); // Set the target date
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    targetDate.setHours(hours, minutes, seconds, 0); // Set the time components
    return targetDate;
  }
  reloadPage() {
    this.location.go(this.location.path());
    window.location.reload();
  }
  goToClassLesson(classID: string) {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    let getClass = this.classes.find((classItem) => classItem.id === classID);
    console.log(getClass);
    this.goTo(
      '/lesson-planner/plan/' +
        getClass.plan.id +
        '/lesson/' +
        getClass.lesson.id +
        '/schedule/' +
        this.schedid
    );
  }
}
