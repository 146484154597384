<div class="schedule-calendar-container">
  <div class="schedule-calendar-content pt-0">
    <div class="d-flex justify-content-between">
      <span class="schedule-text" *ngIf="blockid">Schedule</span>
      <div class="d-flex align-items-center">
        <mat-icon
          *ngIf="blockid"
          class="material-icons-outlined mx-3"
          matTooltip="Schedule Options"
          [matMenuTriggerFor]="menuBlockScheduleOptions"
          >more_vert</mat-icon
        >
        <button
          mat-raised-button
          class="back-btn form-button"
          type="button"
          tabindex="-1"
          (click)="blockiddata.action()"
          *ngIf="blockiddata?.blockschedid !== undefined"
        >
          Close
        </button>
      </div>
      <button
        mat-flat-button
        cdkFocusInitial
        class="btn-create text-white"
        type="submit"
        *ngIf="!blockid ? !blockiddata?.blockschedid : !blockid"
        (click)="createBlockScheduleDialog(schedid)"
      >
        <mat-icon>add</mat-icon> Create Schedule
      </button>
    </div>
    <full-calendar
      [options]="calendarOptions"
      class="my-5"
      *ngIf="blockid"
      #calendar
    >
      <ng-template #eventContent let-arg>
        <p class="class-name">
          {{ arg.event.title }}
          <small class="fw-normal">({{ arg.timeText }})</small>
        </p>
      </ng-template>
    </full-calendar>

    <div class="mat-elevation-z1 mt-4" *ngIf="false">
      <table mat-table [dataSource]="days[0].children" class="mat-table">
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef class="invisible">Period</th>
          <td
            mat-cell
            *matCellDef="let period; let rowIndex = index"
            class="text-center"
            (click)="handleClickCell(rowIndex + 1, 0)"
            [matMenuTriggerFor]="menuPeriodOptions"
          >
            <div class="wrapper">
              <p class="fw-bold my-0">{{ period.name }}</p>
              ({{ "1970-01-01T" + period.start_time | date : "hh:mm a" }} ~
              {{ "1970-01-01T" + period.end_time | date : "hh:mm a" }})
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngFor="let day of days; let colIndex = index"
          [matColumnDef]="day.name"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            (click)="handleClickCell(0, colIndex + 1)"
            #menuDayOptionsTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menuDayOptions"
            [attr.style]="'width:calc(100% / ' + (days.length + 1) + ')'"
          >
            <span>{{ day.name }}</span>
          </th>
          <td
            mat-cell
            *matCellDef="let period; let rowIndex = index"
            (mousedown)="onSelectionStart(rowIndex, colIndex)"
            (mouseup)="
              onSelectionEnd(rowIndex, colIndex);
              menuCellsOptionsTrigger.openMenu()
            "
            [ngClass]="
              days[colIndex].children[rowIndex]?.merge !== null &&
              days[colIndex].children[rowIndex]?.merge.id !==
                days[colIndex].children[rowIndex]?.merge.related_merges[0]
                ? 'd-none'
                : days[colIndex].children[rowIndex]?.selected
                ? 'selected'
                : ''
            "
            (click)="handleClickCell(rowIndex + 1, colIndex + 1)"
            [attr.rowspan]="
              days[colIndex].children[rowIndex]?.merge !== null
                ? days[colIndex].children[rowIndex]?.merge.related_merges.length
                : null
            "
            [attr.style]="
              days[colIndex].children[rowIndex]?.class_infos
                ? 'background-color:' +
                  days[colIndex].children[rowIndex]?.class_infos[0]?.lesson
                    .color
                : ''
            "
          >
            <div
              class="wrapper"
              #menuCellsOptionsTrigger="matMenuTrigger"
              [matMenuTriggerFor]="menuCellsOptions"
              [matMenuTriggerData]="{
                data: {
                  period: days[colIndex].children[rowIndex],
                  day: colIndex,
                }
              }"
            >
              @if(days[colIndex].children[rowIndex]?.class_infos) {
              <p class="fw-bold my-0 text-white">
                {{ days[colIndex].children[rowIndex].class_infos[0].name }}
                <span class="lesson-name d-block fw-normal">{{
                  days[colIndex].children[rowIndex].class_infos[0].lesson.name
                }}</span>
              </p>
              }
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
<mat-menu #menuDayOptions="matMenu">
  <button
    mat-menu-item
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Add a Day',
        option: 1,
        type: 'day'
      })
    "
  >
    Add a Day
  </button>
  <button
    mat-menu-item
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Rename This Day',
        option: 2,
        type: 'day'
      })
    "
  >
    Rename This Day
  </button>
  <button
    mat-menu-item
    class="text-danger"
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Remove Last Day',
        option: 3,
        type: 'day'
      })
    "
  >
    Remove Last Day
  </button>
</mat-menu>
<mat-menu #menuPeriodOptions="matMenu">
  <button
    mat-menu-item
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Add a Period',
        option: 1,
        type: 'period'
      })
    "
  >
    Add a Period
  </button>
  <button
    mat-menu-item
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Adjust End time',
        option: 4,
        type: 'period'
      })
    "
  >
    Adjust Time Period
  </button>
  <button
    mat-menu-item
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Rename this Period',
        option: 2,
        type: 'period',
        data: data
      })
    "
  >
    Rename Period
  </button>
  <button
    mat-menu-item
    class="text-danger"
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Remove Last Period',
        option: 3,
        type: 'period'
      })
    "
  >
    Remove Last Period
  </button>
</mat-menu>
<mat-menu #menuCellsOptions="matMenu" (closed)="onMenuClosed()">
  <ng-template matMenuContent let-data="data">
    @if (data.period.class_infos){
    <button
      mat-menu-item
      (click)="
        goTo(
          '/lesson-planner/plan/' +
            data.period.class_infos[0].plan +
            '/lesson/' +
            data.period.class_infos[0].lesson.id +
            '/schedule/' +
            schedid
        )
      "
    >
      View the {{ frontEndNames.lesson }}
    </button>
    <button
      mat-menu-item
      (click)="
        openDialog(DayDialogTemplate, {
          title: 'Remove Class',
          option: 2,
          type: 'class',
          data: data
        })
      "
    >
      Remove Class
    </button>
    @if (data.period.merge !== null){
    <button
      mat-menu-item
      (click)="
        openDialog(DayDialogTemplate, {
          title: 'Unmerge Periods',
          option: 4,
          type: 'class',
          data: data
        })
      "
    >
      Unmerge Periods
    </button>
    } }@else{ @if (multiselectedPeriods.length > 0){
    <button
      mat-menu-item
      (click)="
        this.blockiddata?.blockschedid !== undefined
          ? actionBlockDayPeriod(
              multiselectedPeriods[0].class_infos === null ? 5 : 3,
              'class',
              data
            )
          : openDialog(DayDialogTemplate, {
              title: 'Merge Periods',
              option: multiselectedPeriods[0].class_infos === null ? 5 : 3,
              type: 'class',
              data: data
            })
      "
    >
      {{
        multiselectedPeriods[0].class_infos === null
          ? "Assign Class & Merge Periods"
          : "Merge Periods"
      }}
    </button>
    }@else{
    <button
      mat-menu-item
      (click)="
        this.blockiddata?.blockschedid !== undefined
          ? actionBlockDayPeriod(1, 'class', data)
          : openDialog(DayDialogTemplate, {
              title: 'Assign Class',
              option: 1,
              type: 'class',
              data: data
            })
      "
    >
      Assign Class
    </button>
    @if (data.period.merge !== null){
    <button
      mat-menu-item
      (click)="
        openDialog(DayDialogTemplate, {
          title: 'Unmerge Periods',
          option: 4,
          type: 'class',
          data: data
        })
      "
    >
      Unmerge Periods
    </button>
    } } }
  </ng-template>
</mat-menu>
<mat-menu #menuBlockScheduleOptions="matMenu">
  <button
    mat-menu-item
    (click)="
      openDialog(DayDialogTemplate, {
        title: 'Clear Classes',
        option: 2,
        type: 'option',
        data: data
      })
    "
    class="text-danger"
  >
    Clear Classes
  </button>
  <button mat-menu-item class="text-danger" (click)="deleteBlockSchedule()">
    Delete Schedule
  </button>
</mat-menu>
<ng-template #DayDialogTemplate>
  <div class="text-center" [ngSwitch]="data.type">
    <div class="dialog-for-class" *ngSwitchCase="'class'">
      @if(data.option === 1){
      <div class="d-flex align-items-center justify-content-between assign">
        <h1 mat-dialog-title class="m-0 py-2 px-0">
          {{ data.title }}
        </h1>
        <button
          mat-raised-button
          class="form-button cs-button me-0"
          (click)="
            openDialog(DayDialogTemplate, {
              title: 'Create a Class for this Term',
              option: 6,
              type: 'class',
              data: data
            })
          "
        >
          Create a class for this term
        </button>
      </div>
      }@else if(data.option === 2){
      <div class="d-flex align-items-center justify-content-between assign">
        <p mat-dialog-title class="m-0 py-2 px-0">{{ data.title }}</p>
        <p
          class="text-start view-class m-0"
          (click)="goToClassLesson(data.data.class_info_id)"
        >
          View the {{ frontEndNames.lesson | lowercase }}
        </p>
      </div>
      }@else{
      <h1 mat-dialog-title class="m-0 py-2 px-0">
        {{ data.title }}
      </h1>
      }
      <div
        mat-dialog-content
        class="text-start pt-2 pb-0 px-0 overflow-x-hidden"
        [ngSwitch]="data.option"
      >
        <ng-container *ngSwitchCase="1">
          <form [formGroup]="assignClassForm">
            <small class="text-danger" *ngIf="classes.length === 0"
              >*Set up a class first</small
            >
            <div class="row">
              <mat-form-field appearance="fill" class="col-md-12">
                <mat-label>Select class</mat-label>
                <mat-select
                  [(value)]="selectedClass"
                  [disabled]="classes.length === 0"
                  required
                >
                  <mat-option *ngFor="let class of classes" [value]="class.id">
                    {{ class.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-label class="d-block">Start time</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    placeholder="0:00 AM/PM"
                    formControlName="startTime"
                    [ngxTimepicker]="startTime"
                  />
                  <ngx-material-timepicker-toggle matSuffix [for]="startTime"
                    ><img
                      ngxMaterialTimepickerToggleIcon
                      src="../../../../assets/images/clock-icon.svg"
                      alt="calendar icon"
                  /></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker
                    #startTime
                    [theme]="timeTheme"
                  ></ngx-material-timepicker>
                  <mat-error> Start time is required </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-label class="d-block">End time</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    placeholder="0:00 AM/PM"
                    formControlName="endTime"
                    [ngxTimepicker]="endTime"
                  />
                  <ngx-material-timepicker-toggle matSuffix [for]="endTime"
                    ><img
                      ngxMaterialTimepickerToggleIcon
                      src="../../../../assets/images/clock-icon.svg"
                      alt="calendar icon"
                  /></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker
                    #endTime
                    [theme]="timeTheme"
                  ></ngx-material-timepicker>
                  <mat-error> Start time is required </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <form [formGroup]="assignClassForm">
            <div class="row">
              <div class="col-md-6">
                <mat-label class="d-block">Start time</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    placeholder="0:00 AM/PM"
                    formControlName="startTime"
                    [ngxTimepicker]="startTime"
                  />
                  <ngx-material-timepicker-toggle matSuffix [for]="startTime"
                    ><img
                      ngxMaterialTimepickerToggleIcon
                      src="../../../../assets/images/clock-icon.svg"
                      alt="calendar icon"
                  /></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker
                    #startTime
                    [theme]="timeTheme"
                  ></ngx-material-timepicker>
                  <mat-error> Start time is required </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-label class="d-block">End time</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    placeholder="0:00 AM/PM"
                    formControlName="endTime"
                    [ngxTimepicker]="endTime"
                  />
                  <ngx-material-timepicker-toggle matSuffix [for]="endTime"
                    ><img
                      ngxMaterialTimepickerToggleIcon
                      src="../../../../assets/images/clock-icon.svg"
                      alt="calendar icon"
                  /></ngx-material-timepicker-toggle>
                  <ngx-material-timepicker
                    #endTime
                    [theme]="timeTheme"
                  ></ngx-material-timepicker>
                  <mat-error> Start time is required </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          Are you sure to merge the selected periods?
        </ng-container>
        <ng-container *ngSwitchCase="4">
          Are you sure to unmerge the selected period?
        </ng-container>
        <ng-container *ngSwitchCase="5">
          <small class="text-danger" *ngIf="classes.length === 0"
            >*Set up a class first</small
          >
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Select class</mat-label>
            <mat-select
              [(value)]="selectedClass"
              [disabled]="classes.length === 0"
              required
            >
              <mat-option *ngFor="let class of classes" [value]="class.id">
                {{ class.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label
              >Select a {{ frontEndNames.lesson | lowercase }}</mat-label
            >
            <mat-select [(value)]="selectedLesson" required>
              <mat-option *ngFor="let lesson of lessons" [value]="lesson.id">
                {{ lesson.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Class name</mat-label>
            <input
              matInput
              placeholder="Your class name"
              [(ngModel)]="inputClassName"
              required
            />
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <div *ngSwitchCase="'option'">
      <h1 mat-dialog-title class="mb-0">{{ data.title }}</h1>
      <div mat-dialog-content class="pt-2" [ngSwitch]="data.option">
        <ng-container *ngSwitchCase="1">
          This action will remove all classes and<br />merged periods for this
          schedule.<br />Are you sure you want to start over?
        </ng-container>
        <ng-container *ngSwitchCase="2">
          Are you sure to clear all classes on this schedule?
        </ng-container>
      </div>
    </div>
    <!-- Dialog Actions -->
    @if(data.type === "class"){
    <div mat-dialog-actions class="d-flex justify-content-end mb-2 mt-4">
      <button
        mat-button
        *ngIf="data.option === 6"
        (click)="
          openDialog(DayDialogTemplate, {
            title: 'Assign Class',
            option: 1,
            type: 'class',
            data: data.data.data
          })
        "
      >
        Back
      </button>
      <button
        mat-button
        *ngIf="data.option === 2"
        class="form-button me-3 unassign ms-5"
        (click)="actionBlockDayPeriod(2, 'class', data.data)"
      >
        Unassign this class
      </button>
      <button
        mat-button
        mat-dialog-close
        cdkFocusInitial
        class="btn-cancel px-4"
      >
        {{
          data.option === 1 || data.option === 6 || data.option === 2
            ? "Cancel"
            : "No"
        }}
      </button>
      <button
        *ngIf="data.option != 2"
        mat-button
        color="default"
        (click)="actionBlockDayPeriod(data.option, data.type, data.data)"
        type="submit"
        class="cs-button px-4"
      >
        {{ data.option === 1 ? "Save" : data.option === 6 ? "Add" : "Yes" }}
      </button>
      <ng-container *ngIf="data.option === 2">
        <button
          class="cs-button px-4"
          mat-button
          (click)="actionBlockDayPeriod(3, data.type, data.data)"
        >
          Update class period
        </button>
      </ng-container>
    </div>
    }@else if(data.type === "option"){
    <div mat-dialog-actions class="d-flex justify-content-center mb-2">
      <button mat-button mat-dialog-close>No</button>
      <button
        mat-button
        color="default"
        (click)="optionsBlockSchedule(data.option)"
        type="submit"
        cdkFocusInitial
      >
        Yes
      </button>
    </div>
    } @else{
    <div mat-dialog-actions class="d-flex justify-content-center mb-2">
      <button mat-button mat-dialog-close>
        {{
          data.option === 2 || (data.option === 4 && data.type === "period")
            ? "Cancel"
            : "No"
        }}
      </button>
      <button
        mat-button
        class="cs-button px-4"
        color="default"
        (click)="actionBlockDayPeriod(data.option, data.type, data.data)"
        type="submit"
        cdkFocusInitial
      >
        {{
          data.option === 2 || (data.option === 4 && data.type === "period")
            ? "Save"
            : "Yes"
        }}
      </button>
    </div>
    }
  </div>
</ng-template>
