<div class="px-3 pb-4">
  <h2 class="text-left mt-3 mb-0">Setup Schedule</h2>
  <hr class="mt-2" />
  <form
    class="mat-typography mt-4 px-3"
    [formGroup]="blockScheduleForm"
    (ngSubmit)="submitForm()"
  >
    <div class="input-group mb-2">
      <label class="input-group-text col-4 p-3" id="name"
        >Schedule Name<span class="text-danger">*</span></label
      >
      <input
        id="name"
        type="text"
        class="form-control col-7"
        aria-label="Schedule Name"
        aria-describedby="schedule_name"
        formControlName="name"
        #scheduleNameInput
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The name of the schedule. This is used to identify the schedule in the lesson planner."
        ></mat-icon
      ></span>
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="adjust_around_holidays"
        >Adjust around holidays</mat-checkbox
      >
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="adjust_around_pro_d"
        >Adjust around professional development days</mat-checkbox
      >
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="weekends_off"
        >No classes on weekends</mat-checkbox
      >
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="calendar_week_cycle"
        >Use a Monday - Friday Cycle</mat-checkbox
      >
    </div>
    <div
      class="input-group mt-2"
      *ngIf="!blockScheduleForm.value.calendar_week_cycle"
    >
      <label class="input-group-text col-4 p-3" id="days_per_cycle"
        >Days Per Cycle<span class="text-danger">*</span></label
      >
      <input
        id="days_per_cycle"
        type="number"
        class="form-control col-7"
        placeholder="Number of Days Per Cycle"
        aria-label="Number of Days Per Cycle"
        aria-describedby="days_per_cycle"
        formControlName="days_per_cycle"
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The number of days before the schedule repeats. A typical Monday to Friday cycle is five days."
        ></mat-icon
      ></span>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <button
        mat-flat-button
        color="basic"
        mat-dialog-close
        class="btn-cancel mx-3"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        color="warn"
        class="btn-save mx-3"
        type="submit"
        [disabled]="!blockScheduleForm.valid"
      >
        Save
      </button>
    </div>
  </form>
</div>
