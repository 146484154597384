<div
  class="position-absolute top-50 start-50 tour-middle"
  tourAnchor="adjust.schedule"
></div>
<div class="grid-container">
  <div class="schedule-header">
    <div class="left-section">
      <button
        mat-fab
        extended
        class="form-button cs-button link back-button"
        (click)="goBack()"
      >
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
    </div>
    <div class="right-section" *ngIf="!schedId">
      <button
        mat-raised-button
        class="form-button cs-button"
        (click)="goPlanNow()"
      >
        Plan Now
      </button>
    </div>
  </div>

  <div class="row mt-4 schedule-details">
    <div class="col-lg-9 col-sm-12 schedule-details-wrapper">
      <div class="rounded-4 bg-white schedule-details-container">
        <div class="schedule-name">
          <span class="schedule-name-text">{{ schedules?.name }}</span>
          <span>
            <mat-icon
              class="material-icons-outlined"
              matTooltip="Term Options"
              [matMenuTriggerFor]="menuTermOptions"
              >more_vert</mat-icon
            >
          </span>
        </div>

        <div class="schedule-more-details">
          <div class="detail">
            <span> Date: </span>
            <span>
              {{ schedules?.start_date | date : "MMM d, y" }} -
              {{ schedules?.end_date | date : "MMM d, y" }}
            </span>
          </div>
          <div class="detail">
            <span> Country: </span>
            <span>
              {{ schedules?.location?.country?.name }}
            </span>
          </div>
          <div class="detail">
            <span> Province: </span>
            <span>
              {{ schedules?.location?.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="rounded-4 bg-white pt-1 pb-4 mt-4">
        @if (schedules.block_schedules?.length === 0){
        <app-block-schedule-calendar
          [blockid]="''"
        ></app-block-schedule-calendar>
        }@else{ @for (block of schedules?.block_schedules; track block;) {
        <app-block-schedule-calendar
          [blockid]="block"
          [schedules]="schedules"
        ></app-block-schedule-calendar>
        } }
      </div>
    </div>
    <div class="col-lg-3 col-sm-12 schedule-calendar-wrapper">
      <div class="rounded-4 bg-white schedule-calendar-container">
        <app-schedule-calendar
          (monthChanged)="onMonthChanged($event)"
        ></app-schedule-calendar>
        <app-schedule-calendar
          [isSecondCalendar]="true"
          [secondCalMonth]="secondCalendarDate"
        ></app-schedule-calendar>
      </div>
      <div class="mt-4 schedule-stickynotes-container" *ngIf="noteData">
        <app-sticky-note
          [content]="noteData"
          [heightInput]="'420px'"
          [isFromPlanner]="true"
        ></app-sticky-note>
      </div>
    </div>
    <!--<div class="col-12 rounded-4 bg-white"></div>-->
  </div>
</div>
<mat-menu #menuTermOptions="matMenu">
  <!-- <button mat-menu-item (click)="editSchedule()">Edit Term</button> -->
  <button mat-menu-item (click)="deleteSchedule(schedId)" class="text-danger">
    Delete Term
  </button>
</mat-menu>
