<div class="px-3 pb-4">
  <h2 class="text-center mt-3">
    <img src="./assets/images/update.png" alt="book-icon" />
  </h2>
  <div class="mat-typography text-center">
    <h2>{{ data.title }}</h2>
    <p [innerHtml]="data.body"></p>
  </div>
  <div class="mt-3 text-center">
    <button
      mat-flat-button
      color="basic"
      (click)="back()"
      class="btn-cancel mx-1"
    >
      I'll come back later
    </button>
    <button
      mat-flat-button
      cdkFocusInitial
      mat-dialog-close
      color="warn"
      (click)="closeDialog()"
      class="btn-confirm mx-1"
    >
      {{ data.completeBtn }}
    </button>
  </div>
</div>
